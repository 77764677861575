import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import Hero from '../components/Hero'
import PartnersRow from '../components/PartnersRow/PartnersRow'
import ClientTestimonials from '../components/ClientTestimonials/ClientTestimonials'
import RequestCallback from '../components/RequestCallback/RequestCallback'
import ServicesBlock from '../components/Services/ServicesBlock'
import CallToAction from '../components/CTA/CallToAction'
import ContactCTA from '../components/CTA/ContactCTA'
import WhatWeDo from '../components/WhatWeDo'
import CasesBlock from '../components/Cases/CasesBlock'

export default class Landing extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="From success to significance"
            description="Find out how we can grow each other"
          />
          <CallToAction
            ctaType="light"
            title="Bora Growth Partners"
            subtitle="Transforming business from success to significance"
            description="Our mission is to amplify the impact of entrepreneurs in Africa. Today, Bora Growth Partners is a fast growing Advisory, Product development and Growth incubator based in Cape Town. "
            linkTo="/about"
          />
          {/* <WhatWeDo /> */}
          <ServicesBlock showHeader />
          <ContactCTA
            title="Wanna Talk To Us?"
            description="Please feel free to contact us. We’re super happy to talk
          to you. Feel free to ask anything."
          />
          <CasesBlock />
          <ClientTestimonials />
          <RequestCallback renderDark location={this.props.location.pathname} />
          <PartnersRow lightRow />
        </div>
      </Layout>
    )
  }
}
