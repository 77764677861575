import React, { Component } from 'react'

export default class PartnersRow extends Component {
  render() {
    const edgegrowthLogo = require('../../assets/images/Edge-Growth.png')
    const mastercardfoundationLogo = require('../../assets/images/mc-logo-black.svg')
    const anzishaLogo = require('../../assets/images/anzisha-logo.png')
    const emergeLogo = require('../../assets/images/emerge-logo.png')
    const medpagesLogo = require('../../assets/images/medpages.png')
    const fetolaLogo = require('../../assets/images/fetola.png')
    const sabfoundationLogo = require('../../assets/images/sab-foundation.svg')
    const southernambitionLogo = require('../../assets/images/southern-ambition.png')
    const backgroundColor = this.props.lightRow ? 'white' : '#f2f2f2'
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ margin: '0px 0px 0px 0px', padding: '70px 0px 30px 0px' }}
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: backgroundColor }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix  gdlr-core-gallery-item-style-grid gdlr-core-item-pdlr ">
                <div
                  className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                  data-type="carousel"
                  data-column={5}
                  data-nav="none"
                  data-nav-parent="gdlr-core-blog-item"
                >
                  <div
                    className="flex-viewport"
                    style={{ overflow: 'hidden', position: 'relative' }}
                  >
                    <ul
                      className="slides"
                      style={{ width: '1000%', marginLeft: 0 }}
                    >
                      <li
                        className="gdlr-core-item-mglr"
                        style={{
                          width: 172,
                          marginRight: 40,
                          float: 'left',
                          display: 'block',
                        }}
                      >
                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                          <img
                            src={edgegrowthLogo}
                            width={148}
                            height={44}
                            draggable="false"
                          />
                        </div>
                      </li>
                      <li
                        className="gdlr-core-item-mglr"
                        style={{
                          width: 172,
                          marginRight: 40,
                          float: 'left',
                          display: 'block',
                        }}
                      >
                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                          <img
                            src={mastercardfoundationLogo}
                            draggable="false"
                          />
                        </div>
                      </li>

                      <li
                        className="gdlr-core-item-mglr"
                        style={{
                          width: 172,
                          marginRight: 40,
                          float: 'left',
                          display: 'block',
                        }}
                      >
                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                          <img
                            src={fetolaLogo}
                            width={138}
                            height={49}
                            draggable="false"
                          />
                        </div>
                      </li>
                      <li
                        className="gdlr-core-item-mglr"
                        style={{
                          width: 172,
                          marginRight: 40,
                          float: 'left',
                          display: 'block',
                        }}
                      >
                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                          <img
                            src={sabfoundationLogo}
                            width={151}
                            height={43}
                            draggable="false"
                          />
                        </div>
                      </li>
                      <li
                        className="gdlr-core-item-mglr"
                        style={{
                          width: 172,
                          marginRight: 40,
                          float: 'left',
                          display: 'block',
                        }}
                      >
                        <div className="gdlr-core-gallery-list gdlr-core-media-image">
                          <img
                            src={medpagesLogo}
                            width={151}
                            height={43}
                            draggable="false"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
