import React, { Component } from 'react'

export default class WhatWeDo extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '70px 0px 40px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                  style={{ maxWidth: 900 }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: 50 }}
                    >
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h4
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 16,
                            fontWeight: 700,
                            letterSpacing: 2,
                          }}
                        >
                          What we do<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-tab-item gdlr-core-js gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-tab-style2-horizontal gdlr-core-item-pdlr">
                      <div className="gdlr-core-tab-item-title-wrap clearfix gdlr-core-title-font">
                        <div
                          className="gdlr-core-tab-item-title  gdlr-core-active"
                          data-tab-id={1}
                        >
                          Tab 1
                        </div>
                        <div
                          className="gdlr-core-tab-item-title "
                          data-tab-id={2}
                        >
                          Tab 2
                        </div>
                        <div
                          className="gdlr-core-tab-item-title "
                          data-tab-id={3}
                        >
                          Tab 3
                        </div>
                        <div className="gdlr-core-tab-item-title-line gdlr-core-skin-divider" />
                      </div>
                      <div className="gdlr-core-tab-item-content-wrap clearfix">
                        <div
                          className="gdlr-core-tab-item-content  gdlr-core-active"
                          data-tab-id={1}
                        >
                          <p>
                            A wonderful serenity has taken possession of my
                            entire soul, like these sweet mornings of spring
                            which I enjoy with my whole heart. I am alone, and
                            feel the charm of existence in this spot, which was
                            created for the bliss of souls like mine. I am so
                            happy, my dear friend, so absorbed in the exquisite
                            sense of mere tranquil existence, that I neglect my
                            talents. I should be incapable of drawing a single
                            stroke at the present moment; and yet I feel that I
                            never was a greater artist than now. When, while the
                            lovely valley teems with vapour around me, and the
                            meridian sun strikes the upper surface of the
                            impenetrable foliage of my trees.
                          </p>
                          <p>
                            And but a few stray gleams steal into the inner
                            sanctuary, I throw myself down among the tall grass
                            by the trickling stream; and, as I lie close to the
                            earth, a thousand unknown plants are noticed by me:
                            when I hear the buzz of the little world among the
                            stalks, and grow familiar with the countless
                            indescribable forms of the insects and flies, then I
                            feel the presence of the Almighty, who formed us in
                            his own image, and the breath
                          </p>
                        </div>
                        <div
                          className="gdlr-core-tab-item-content "
                          data-tab-id={2}
                        >
                          <p>
                            A wonderful serenity has taken possession of my
                            entire soul, like these sweet mornings of spring
                            which I enjoy with my whole heart. I am alone, and
                            feel the charm of existence in this spot, which was
                            created for the bliss of souls like mine. I am so
                            happy, my dear friend, so absorbed in the exquisite
                            sense of mere tranquil existence, that I neglect my
                            talents. I should be incapable of drawing a single
                            stroke at the present moment; and yet I feel that I
                            never was a greater artist than now. When, while the
                            lovely valley teems with vapour around me, and the
                            meridian sun strikes the upper surface of the
                            impenetrable foliage of my trees, and but a few
                            stray gleams steal into the inner sanctuary, I throw
                            myself down among the tall grass by the trickling
                            stream; and, as I lie close to the earth, a thousand
                            unknown plants are noticed by me: when I hear the
                            buzz of the little world among the stalks, and grow
                            familiar with the countless indescribable forms of
                            the insects and flies, then I feel the presence of
                            the Almighty, who formed us in his own image, and
                            the breath
                          </p>
                        </div>
                        <div
                          className="gdlr-core-tab-item-content "
                          data-tab-id={3}
                        >
                          <p>
                            A wonderful serenity has taken possession of my
                            entire soul, like these sweet mornings of spring
                            which I enjoy with my whole heart. I am alone, and
                            feel the charm of existence in this spot, which was
                            created for the bliss of souls like mine. I am so
                            happy, my dear friend, so absorbed in the exquisite
                            sense of mere tranquil existence, that I neglect my
                            talents. I should be incapable of drawing a single
                            stroke at the present moment; and yet I feel that I
                            never was a greater artist than now. When, while the
                            lovely valley teems with vapour around me, and the
                            meridian sun strikes the upper surface of the
                            impenetrable foliage of my trees, and but a few
                            stray gleams steal into the inner sanctuary, I throw
                            myself down among the tall grass by the trickling
                            stream; and, as I lie close to the earth, a thousand
                            unknown plants are noticed by me: when I hear the
                            buzz of the little world among the stalks, and grow
                            familiar with the countless indescribable forms of
                            the insects and flies, then I feel the presence of
                            the Almighty, who formed us in his own image, and
                            the breath
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
