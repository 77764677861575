import React, { Component } from 'react'
import ClientTestimonialCard from './ClientTestimonialCard'

export default class ClientTestimonials extends Component {
  render() {
    const client = require('../../assets/images/personnel-1-sq-150x150.jpg')
    const ronrico = require('../../assets/images/Ronrico-Logo.png')
    const speabora = "https://is3-ssl.mzstatic.com/image/thumb/Purple62/v4/d9/40/7d/d9407d8c-7c08-d464-0ae6-c47755908f34/mzl.vztlmgvc.jpg/1200x630wa.jpg"//require('../../assets/images/speabora.png')
    const mastercard = require('../../assets/images/mc-logo-black.svg')


    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '100px 0px 0px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                style={{ paddingBottom: 60 }}
              >
                <div className="gdlr-core-title-item-title-wrap ">
                  <h3
                    className="gdlr-core-title-item-title gdlr-core-skin-title "
                    style={{ fontSize: 38, textTransform: 'none' }}
                  >
                    Hear from some of our clients<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                  </h3>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-testimonial-item gdlr-core-item-pdb clearfix  gdlr-core-testimonial-style-left">
                <ClientTestimonialCard
                  testimonial="We were able to rely on Bora Growth team in implementing the support program to our East African cohorts with immediate and tangible outcome"
                  clientName="Lerato Mdluli"
                  clientDesignation="Youth Entrepreneur Support Unit Manager, Mastercard ANZISHA"
                  image={mastercard}
                />
                <ClientTestimonialCard
                  testimonial="Diverse team with diverse skill sets and energetic. Our business planning project received timely and adequate support with great insights"
                  clientName="Ron"
                  clientDesignation="CEO, Ronrico Agri Import/Export"
                  image={ronrico}
                />
                <ClientTestimonialCard
                  testimonial="Product was scoped and developed within budget and managed to launch our MVP 3 months ahead of time. Impressive technology team"
                  clientName="George Sechu"
                  clientDesignation="CTO, Speabora App"
                  image={speabora}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
