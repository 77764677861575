import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class ServiceCard extends Component {
  render() {
    const service1 = require('../../assets/images/hp2-service-1.jpg')
    const service2 = require('../../assets/images/hp2-service-2.jpg')
    const service3 = require('../../assets/images/hp2-service-3.jpg')
    const service4 = require('../../assets/images/hp2-service-4.jpg')
    const service5 = require('../../assets/images/hp2-service-5.jpg')
    const service6 = require('../../assets/images/hp2-service-6.jpg')
    return (
      <div className="gdlr-core-hover-box-column gdlr-core-item-pdlr gdlr-core-item-mgb  gdlr-core-column-20">
        <div
          className="gdlr-core-hover-box  gdlr-core-outer-frame-element  clearfix"
          style={{
            boxShadow: '0 0 74px rgba(10, 10, 10,0.07)',
            MozBoxShadow: '0 0 74px rgba(10, 10, 10,0.07)',
            WebkitBoxShadow: '0 0 74px rgba(10, 10, 10,0.07)',
          }}
        >
          <div className="gdlr-core-hover-box-thumbnail-top gdlr-core-media-image">
            <img src={this.props.image} width={700} height={423} />
          </div>
          <div
            className="gdlr-core-hover-box-content-wrap"
            style={{ paddingLeft: 35 }}
          >
            <h3
              className="gdlr-core-hover-box-title gdlr-core-skin-title"
              style={{
                fontSize: 20,
                textTransform: 'none',
                color: '#254099',
              }}
            >
              {this.props.title}
            </h3>
            <div
              className="gdlr-core-hover-box-content gdlr-core-skin-content"
              style={{ color: '#898989' }}
            >
              <p>{this.props.description}</p>
            </div>
          </div>
          <Link className="gdlr-core-hover-box-link" to={this.props.linkTo} />
        </div>
      </div>
    )
  }
}
