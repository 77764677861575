import React, { Component } from 'react'

export default class ClientTestimonialCard extends Component {
  render() {
    const client = require('../../assets/images/personnel-1-sq-150x150.jpg')
    return (
      <div className="gdlr-core-testimonial-column gdlr-core-item-pdlr gdlr-core-item-mgb  gdlr-core-column-20">
        <div className="gdlr-core-testimonial clearfix">
          <div className="gdlr-core-testimonial-quote gdlr-core-quote-font">
            “
          </div>
          <div className="gdlr-core-testimonial-content-wrap">
            <div
              className="gdlr-core-testimonial-content gdlr-core-info-font gdlr-core-skin-content"
              style={{ fontSize: 15 }}
            >
              <p>{this.props.testimonial}</p>
            </div>
            <div className="gdlr-core-testimonial-author-wrap clearfix">
              <div className="gdlr-core-testimonial-author-image gdlr-core-media-image">
                <img src={this.props.image} width={150} height={150} />
              </div>
              <div className="gdlr-core-testimonial-author-content">
                <div className="gdlr-core-testimonial-title gdlr-core-title-font gdlr-core-skin-title">
                  {this.props.clientName}
                </div>
                <div className="gdlr-core-testimonial-position gdlr-core-info-font gdlr-core-skin-caption">
                  {this.props.clientDesignation}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
