import React, { Component } from 'react'
import { Link } from 'gatsby'
import ServiceCard from './ServiceCard'
import ContactCTA from '../CTA/ContactCTA'

export default class ServicesBlock extends Component {
  renderHeader() {
    if (!this.props.showHeader) {
      return <div />
    }
    return (
      <div className="gdlr-core-pbf-wrapper ">
        <div className="gdlr-core-pbf-background-wrap">
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            data-parallax-speed="0.04"
          />
        </div>

        <div
          className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
          style={{ paddingBottom: 27 }}
        >
          <div className="gdlr-core-title-item-title-wrap ">
            <h3
              className="gdlr-core-title-item-title gdlr-core-skin-title "
              style={{
                fontSize: 44,
                fontWeight: 600,
                letterSpacing: 0,
                textTransform: 'none',
                color: '#232323',
              }}
            >
              Services We Provide<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
            </h3>
          </div>
          <span
            className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
            style={{
              fontSize: 14,
              fontStyle: 'normal',
              letterSpacing: 2,
              textTransform: 'uppercase',
              marginTop: 5,
            }}
          >
            Have a look at what we do
          </span>
        </div>
        <div className="gdlr-core-pbf-element">
          <div
            className="gdlr-core-divider-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-divider-item-normal"
            style={{ paddingBottom: 25 }}
          >
            <div
              className="gdlr-core-divider-container gdlr-core-center-align"
              style={{ maxWidth: 54 }}
            >
              <div
                className="gdlr-core-divider-line gdlr-core-skin-divider"
                style={{ borderColor: '#254099', borderBottomWidth: 4 }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderOtherServices() {
    const service3 = require('../../assets/images/rawpixel-574844-unsplash.jpg')
    const service4 = require('../../assets/images/alex-knight-181471-unsplash.jpg')
    if (this.props.showOtherServices) {
      return (
        <div>
          <ServiceCard
            title="Creative Services"
            description="Bora Growth Partners assists businesses in developing and creating your brand with content creation, illustration design for your brand, product design and other needs"
            linkTo="/creative-services"
            image={service3}
          />
          <ServiceCard
            title="Post Investment Support"
            description="Bora Growth Partners assists businesses in creating, designing,planning and implementing a clear strategy that successfully aligns their goals, network and vision"
            linkTo="/post-investment-support"
            image={service4}
          />
        </div>
      )
    }
  }
  render() {
    const backgroundColor = this.props.lightRow ? 'white' : '#f2f2f2'
    const service1 = require('../../assets/images/rawpixel-252130-unsplash.jpg')
    const service2 = require('../../assets/images/rawpixel-557123-unsplash.jpg')
    const service3 = require('../../assets/images/rawpixel-574844-unsplash.jpg')
    const service4 = require('../../assets/images/alex-knight-181471-unsplash.jpg')
    return (
      <div>
        <div
          className="gdlr-core-pbf-wrapper-content gdlr-core-js "
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-element"
              style={{
                padding: '30px 0px 0px 0px',
              }}
            >
              {this.renderHeader()}
              <div
                className="gdlr-core-pbf-wrapper "
                style={{
                  margin: '0px 0px 0px 0px',
                  padding: '0px 0px 100px 0px',
                }}
              >
                <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                  <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div className="gdlr-core-pbf-element">
                      <div
                        className="gdlr-core-hover-box-item gdlr-core-item-pdb clearfix  gdlr-core-left-align"
                        style={{ paddingBottom: 20 }}
                      />
                      <ServiceCard
                        title="Business Strategy"
                        description="Bora Growth Partners assists businesses in creating, designing,planning and implementing a clear strategy that successfully aligns their goals, network and vision"
                        linkTo="/business-strategy"
                        image={service1}
                      />
                      <ServiceCard
                        title="Technology Advisory"
                        description="Bora Growth Partners assists businesses in creating, designing,planning and implementing a clear strategy that successfully aligns their goals, network and vision"
                        linkTo="/technology-advisory"
                        image={service2}
                      />
                      <ServiceCard
                        title="Market Research"
                        description="Bora Growth Partners assists businesses in creating, designing,planning and implementing a clear strategy that successfully aligns their goals, network and vision"
                        linkTo="/market-research"
                        image={service3}
                      />
                      {this.renderOtherServices()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
