import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class CallToAction extends Component {
  renderRightAligned() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '170px 0px 90px 0px' }}
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#f7f7f7' }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                  style={{ maxWidth: 960 }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-stunning-text-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-right-align gdlr-core-stunning-text-caption-below-title">
                      <h3 className="gdlr-core-stunning-text-item-title">
                        {this.props.title}
                      </h3>
                      <div
                        className="gdlr-core-stunning-text-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                        style={{ fontSize: 18 }}
                      >
                        {this.props.subtitle}
                      </div>
                      <div
                        className="gdlr-core-stunning-text-item-content"
                        style={{ fontSize: 19 }}
                      >
                        <p>{this.props.description}</p>
                      </div>
                      <Link
                        className="gdlr-core-stunning-text-item-link gdlr-core-info-font"
                        to={this.props.linkTo}
                        style={{ fontSize: 19 }}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLeftAligned() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '170px 0px 90px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                  style={{ maxWidth: 960 }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-stunning-text-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-stunning-text-caption-above-title">
                      <h3
                        className="gdlr-core-stunning-text-item-title"
                        style={{ fontSize: 67 }}
                      >
                        {this.props.title}
                      </h3>
                      <div
                        className="gdlr-core-stunning-text-item-content"
                        style={{ fontSize: 20 }}
                      >
                        <p>{this.props.description}</p>
                      </div>
                      <Link
                        className="gdlr-core-stunning-text-item-link gdlr-core-info-font"
                        to={this.props.linkTo}
                        style={{ fontSize: 20 }}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderDarkCTA() {
    const backgroundImage = this.props.backgroundImage
      ? this.props.backgroundImage
      : null

    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '160px 0px 110px 0px' }}
        data-skin="Dark"
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#143b51' }}
        >
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              opacity: '0.2',
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              data-skin="White Text"
            >
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                  style={{ maxWidth: 960 }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-stunning-text-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-stunning-text-caption-below-title">
                      <h3
                        className="gdlr-core-stunning-text-item-title"
                        style={{ fontSize: 43 }}
                      >
                        {this.props.title}
                      </h3>
                      <div className="gdlr-core-stunning-text-item-caption gdlr-core-info-font gdlr-core-skin-caption">
                        {this.props.subtitle}
                      </div>
                      <div className="gdlr-core-stunning-text-item-content">
                        <p>{this.props.description}</p>
                      </div>
                      <Link
                        className="gdlr-core-stunning-text-item-link gdlr-core-info-font"
                        to={this.props.linkTo}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLightCTA() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '190px 0px 110px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div
                  className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
                  style={{ maxWidth: 960 }}
                >
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-stunning-text-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-stunning-text-caption-above-title">
                      <div className="gdlr-core-stunning-text-item-caption gdlr-core-info-font gdlr-core-skin-caption">
                        {this.props.subtitle}
                      </div>
                      <h3
                        className="gdlr-core-stunning-text-item-title"
                        style={{ fontSize: 43 }}
                      >
                        {this.props.title}
                      </h3>
                      <div className="gdlr-core-stunning-text-item-content">
                        <p>{this.props.description}</p>
                      </div>
                      <Link
                        className="gdlr-core-stunning-text-item-link gdlr-core-info-font"
                        to={this.props.linkTo}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    switch (this.props.ctaType) {
      case 'light':
        return this.renderLightCTA()
      case 'dark':
        return this.renderDarkCTA()
      case 'left':
        return this.renderLeftAligned()
      case 'right':
        return this.renderRightAligned()
      default:
        this.renderLightCTA()
    }
  }
}
